@import '../../../public/assets/sass/main';

.state-box {
  text-align: center;

  img {
    width: 30%;
  }

  h1 {
    font-size: 2rem;
    color: $color-grey-dark-2;
  }
}