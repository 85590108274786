body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: $color-grey-dark;
}

code {
  background-color: $color-primary-light;
  padding: .2rem .5rem;
  color: $color-white;
  border-radius: .3rem;
}