// COLORS
$color-primary: #55c57a;
$color-primary-light: #7ed56f;

$color-secondary-light: #ffb900;

$color-grey-dark: #777;
$color-grey-dark-2: #999;

$color-grey-light: #f7f7f7;

$color-white: #fff;
$color-black: #000;


// FONT
$default-font-size: 1.6rem;