@import '../../../public/assets/sass/main';

.form-control {
  text-align: center;

  &__form {
    padding: .5rem;

    &__input {
      width: 50vw;
      height: 5rem;
      border-radius: .5rem;
      border: 1px solid $color-grey-dark-2;
      font-size: 3rem;
      padding: 0 1rem;
      color: $color-grey-dark;

      &:focus {
        outline: none;
        box-shadow: 0 0 0.5rem 0.1rem $color-secondary-light;
        border: 1px solid $color-secondary-light;
      }

      @include respond(tab-port) {
        width: 70vw;
      }
    }
  }

  &__hint {
    position: absolute;
    right: 25%;
    font-size: 1.5rem;
    color: $color-grey-dark-2;

    @include respond(tab-port) {
      right: 15%;
    }
  }
}