@import '../../../public/assets/sass/main';

.gif_player {
  margin: 1rem;
  cursor: pointer;
 
  .gif-image {
    border: 2px solid $color-grey-dark;
    border-radius: .5rem;
  }

  .playing {
    border: 3px solid $color-secondary-light;
  }
}