@import '../../../public/assets/sass/main';

.header {
  height: 20vh;
  position: relative;

  @include respond(tab-port) {
    height: 10vh;
  }
  
  &__text-box {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0);

    @include respond(tab-port) {
      top: 10%;
      transform: translate(-50%, -10%);
    }

    h1 {
      letter-spacing: 5px;
      font-size: 5rem;
    }

    &__title--main {
      color: $color-primary;
    }

    &__title--sub {
      color: $color-grey-dark;
    }
  }
}

.section-search {
  height: 27.5vh;
  padding: 2rem 0;

  @include respond(tab-port) {
    height: 25vh;
  }
}