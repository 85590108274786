@import '../../../public/assets/sass/main';

.filter-control {
  text-align: center;
  padding: 3rem 0;

  &__button-filter {
    background-color: transparent;
    border: 2px solid $color-secondary-light;
    color: $color-secondary-light;

    &--selected {
      background-color: $color-secondary-light;
      color: $color-white;
    }

    &:hover {
      background-color: $color-secondary-light;
      color: $color-white;
    }

    &:focus {
      outline: none;
    }
  }
}