.image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(27rem, 1fr));
  grid-gap: 0 10px;
  grid-auto-rows: 10px;
  grid-auto-columns: 50px;
  text-align: center;

  img {
    width: 27rem;
  }
}