*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; // 1 rem = 10px, 10px/16px = 62.5%

  @include respond(tab-land) { // width < 1200px ?
    font-size: 56.25%; // 1 rem = 9px, 9px/16px = 56.25%
  }

  @include respond(tab-port) { // width < 900px ?
    font-size: 50%; // 1 rem = 8px, 8px/16px = 50%
  }
  
  @include respond(big-desktop) {
    font-size: 75%; // 1 rem = 12px, 12px/16px = 75%
  }
}

body {
  box-sizing: border-box;
}

button {
  padding: .8rem 2rem;
  margin: .8rem;
  border-radius: .5rem;
  border: 2px solid $color-grey-dark-2;
  background-color: transparent;
  color: $color-grey-dark-2;
  cursor: pointer;
  letter-spacing: .1rem;
}