@import '../../../public/assets/sass/main';

.pagination {
  height: 5vh;
  margin: 5rem auto 2rem auto;
  display: table;

  ul {
    background-color: $color-secondary-light;
    padding-bottom: .5rem;
    border-radius: .5rem;
  }

  &__number {
    display: inline;
    margin: auto 2rem;
    list-style: none;
    color: $color-grey-dark;
    font-size: 2rem;
    cursor: pointer;
  }

  .active {
    color: $color-white;
    font-weight: bolder;
  }
}